<template>
    <div>
      <v-dialog
        v-model="loading"
        persistent
        hide-overlay
      >
      <v-progress-linear
        style="position: absolute; top: 0; left: 0; right: 0;"
        indeterminate
        color="green"
      ></v-progress-linear>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: "Loader",
    props: {
      loading: Boolean
    }
  }
  </script>