import axios from "axios";
const baseUrl = process.env.VUE_APP_ROOT_API+'/management-companies'

export async function getAllUk(params) {
    let items = []
    const response = await axios.get(baseUrl, {
        params: params
    })

    items = response?.data
    return items
}

export async function createUk(params) {
    const response = await axios.post(baseUrl, params)
    return response.data.data
}

export async function showUk(id) {
    const response = await axios.get(baseUrl+'/'+id)
    return response.data.data
}

export async function updateUk(id, params) {
    const response = await axios.put(baseUrl+'/'+id, params)
    return response.data
}

export async function destroyUk(id) {
    const response = await axios.delete(baseUrl+'/'+id)
    return response.data.data
}