import Vue from 'vue'
import Vuex from 'vuex'
import {getAllOsi, showOsi} from "@/services/osiManagementService";
import {Role} from "@/helpers/role";
import {getAllUk, showUk} from "@/services/ukManagementService";
import router from "@/router";
import filterStore from "@/store/filterStore"
import {getKzhkHouses} from "@/services/housesSystemsService";
import query from "@/store/query";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    query,
    filterStore
  },
  state: {
    testCountdown: null,
    authenticated: false,
    user: {},
    osiList: [],
    osiId: null,
    houseList: [],
    houseId: null,
    ukList: [],
    ukId: null,
    isAdmin: false,
    isOsi: false,
    isLowOsi: false,
    isWd: false,
    isMc: false,
    isKzhk: false,
    isKzhkAdmin: false,
    isDispatcher: false,
    isEmergencyDispatcher: false,
    locale: 'ru',
    step: 1,
    sms: null,
    phone: null,
    alert: {
      isVisible: false,
      msg: "",
      color: "",
      type: "error",
    },
    application: {
      residentId: null,
      requestor: {
        iin: null,
        surname: null,
        name: null,
        middleName: null,
        email: null,
        phoneNumber: null,
        numberPhoneCity: null,
        documentNumber: null
      },
      paymentInfo: {
        paymentType: null,
        checkNumber: null,
        checkDate: null,
        payedSum: 0,
        isUserPaid: false,
        payerIin: null,
        payerFullName: null
      },
      fullNameKz: null,
      fullNameRu: null,
      protocolNumber: null,
      protocolDate: null,
      economicActivities: [
        {
          code: null,
          value: 0,
          main: true
        }
      ]
    },
    application_step: 0,
    documentId: null,
    xmlData: null,
    cancelXmlData: null,
    tbXmlData: null,
    leaderXmlData: null,
    leaderDocId: null
  },
  getters: {
    locale: (state) => state.locale,
    authenticated: (state) => !!state.authenticated,
    user: (state) => state.user,
    alert: (state) => state.alert,
    step: (state) => state.step,
    phone: (state) => state.phone,
    osiId: (state) => state.osiId,
    osiList: (state) => state.osiList,
    ukId: (state) => state.ukId,
    ukList: (state) => state.ukList,
    houseId: (state) => state.houseId,
    houseList: (state) => state.houseList,
    isAdmin: (state) => state.isAdmin,
    isOsi: (state) => state.isOsi,
    isLowOsi: (state) => state.isLowOsi,
    isWd: (state) => state.isWd,
    isMc: (state) => state.isMc,
    isKzhk: (state) => state.isKzhk,
    isKzhkAdmin: (state) => state.isKzhkAdmin,
    isDispatcher: (state) => state.isDispatcher,
    isEmergencyDispatcher: (state) => state.isEmergencyDispatcher,
    documentId: (state) => state.documentId,
    xmlData: (state) => state.xmlData,
    cancelXmlData: (state) => state.cancelXmlData,
    tbXmlData: (state) => state.tbXmlData,
    leaderXmlData: (state) => state.leaderXmlData,
    leaderDocId: (state) => state.leaderDocId,
    application: (state) => state.application,
    application_step: (state) => state.application_step,
    sms: (state) => state.sms,
    testCountdown: (state) => state.testCountdown,
  },
  mutations: {
    SET_TEST_COUNTDOWN(state, value) {
      state.testCountdown = value;
    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_ALERT(state, alertData) {
      state.alert = alertData;
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
    SET_STEP(state, val) {
      state.step = val;
    },
    SET_SMS(state, val) {
      state.sms = val;
    },
    SET_PHONE(state, val) {
      state.phone = val;
    },
    SET_OSI(state, val) {
      state.osiId = val;
    },
    SET_OSI_LIST(state, val) {
      state.osiList = val;
    },
    SET_UK(state, val) {
      state.ukId = val
    },
    SET_UK_LIST(state, val) {
      state.ukList = val
    },
    SET_HOUSE(state, val) {
      state.houseId = val;
    },
    SET_HOUSE_LIST(state, val) {
      state.houseList = val;
    },
    SET_IS_ADMIN(state, val) {
      state.isAdmin = val;
    },
    SET_IS_OSI(state, val) {
      state.isOsi = val;
    },
    SET_IS_LOW_OSI(state, val) {
      state.isLowOsi = val;
    },
    SET_IS_WD(state, val) {
      state.isWd = val;
    },
    SET_IS_MC(state, val) {
      state.isMc = val;
    },
    SET_IS_KZHK(state, val) {
      state.isKzhk = val;
    },
    SET_IS_KZHK_ADMIN(state, val) {
      state.isKzhkAdmin = val;
    },
    SET_IS_DISPATCHER(state, val){
      state.isDispatcher = val
    },
    SET_IS_EMERGENCY_DISPATCHER(state, val){
      state.isEmergencyDispatcher = val
    },
    SET_DOCUMENT(state, val) {
      state.documentId = val;
    },
    SET_XML_DATA(state, val) {
      state.xmlData = val;
    },
    SET_CANCEL_XML_DATA(state, val) {
      state.cancelXmlData = val;
    },
    SET_TB_XML_DATA(state, val) {
      state.tbXmlData = val;
    },
    SET_LEADER_XML_DATA(state, val) {
      state.leaderXmlData = val;
    },
    SET_LEADER_DOC_ID(state, val) {
      state.leaderDocId = val;
    },
    SET_REQUEST_NUMBER(state, val) {
      state.requestNumber = val;
    },
    SET_APPLICATION(state, val) {
      state.application = val;
    },
    SET_APPLICATION_STEP(state, val) {
      state.application_step = val;
    }
  },
  actions: {
    setUpDataByRole({commit, dispatch}, data) {
      if (data.role === Role.lowOsiManager) {
        commit('SET_IS_LOW_OSI', true)
        dispatch('setOsi', data.osiId)
      }

      if (data.role === Role.osi) {
        commit('SET_IS_OSI', true)
        dispatch('setOsi', data.osiId)
        dispatch('getHouseListByOsi', data.osiId)
      }

      if (data.role === Role.uk) {
        commit('SET_IS_MC', true)
        dispatch('setUk', data.managementCompanyId)
        dispatch('getOsiListByMc', data.managementCompanyId)
      }

      if (data.role === Role.wd) {
        commit('SET_IS_WD', true)
      }

      if (data.role === Role.kzhk) {
        commit('SET_IS_KZHK', true)
        dispatch('getKzhk')
      }

      if (data.role === Role.kzhkAdmin) {
        commit('SET_IS_KZHK_ADMIN', true)
        dispatch('getKzhk')
      }

      if (data.role === Role.dispatcher) {
        commit('SET_IS_DISPATCHER', true)
        if (data.osiId !== null) {
          dispatch('setOsi', data.osiId)
          dispatch('getHouseListByOsi', data.osiId)
        }
        else {
          dispatch('setUk', data.managementCompanyId)
          dispatch('getOsiListByMc', data.managementCompanyId)
        }
      }

      if (data.role === Role.emergencyDispatcher) {
        commit('SET_IS_EMERGENCY_DISPATCHER', true)
      }

      if (data.role === Role.superAdmin) {
        commit('SET_IS_ADMIN', true)
        //dispatch('getOsiList')
        //dispatch('getUkList')
      }
    },
    async login({ commit, dispatch }, data) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      commit("SET_USER", data);
      localStorage.setItem("u_user", JSON.stringify(data));
      dispatch('setUpDataByRole', data)
    },
    logout({ commit }) {
      commit("SET_USER", {});
      commit('SET_IS_WD', false)
      commit('SET_IS_ADMIN', false)
      commit('SET_IS_MC', false)
      commit('SET_IS_KZHK', false)
      commit('SET_IS_OSI', false)
      commit('SET_IS_LOW_OSI', false)
      commit('SET_IS_DISPATCHER', false)
      commit('SET_IS_EMERGENCY_DISPATCHER', false)
      commit('SET_OSI', null)
      commit('SET_UK', null)
      commit('SET_HOUSE', null)
      commit('SET_HOUSE_LIST', [])

      localStorage.removeItem("u_user");
      router.push({name: 'login'})
    },
    clearFilters({commit}) {
      commit('SET_OSI', null)
    },
    changeLocale({ commit }, locale) {
      commit("SET_LOCALE", locale);
      localStorage.setItem("u_locale", locale);
      location.reload();
    },
    setIsAdmin({commit}, val) {
      commit('SET_IS_ADMIN', val)
    },
    setLocale({ commit }, locale) {
      commit("SET_LOCALE", locale);
      localStorage.setItem("u_locale", locale);
    },
    setStep({ commit }, val) {
      commit("SET_STEP", val);
      localStorage.setItem("u_step", val);
    },
    setSms({ commit }, val) {
      commit("SET_SMS", val);
      localStorage.setItem("u_sms", val);
    },
    setPhone({ commit }, val) {
      commit("SET_PHONE", val);
      localStorage.setItem("u_phone", JSON.stringify(val));
    },
    setDocument({ commit }, val) {
      commit("SET_DOCUMENT", val);
      localStorage.setItem("u_document", JSON.stringify(val));
    },
    setXml({ commit }, val) {
      commit("SET_XML_DATA", val);
      localStorage.setItem("u_xml", JSON.stringify(val));
    },
    setOsi({commit}, id) {
      commit('SET_OSI', id)
      localStorage.setItem("u_osi", JSON.stringify(id));
    },
    setOsiList({commit}, data) {
      commit('SET_OSI_LIST', data)
    },
    setHouse({commit}, id) {
      commit('SET_HOUSE', id)
      localStorage.setItem("u_house", JSON.stringify(id));
    },
    setHouseList({commit}, data) {
      commit('SET_HOUSE_LIST', data)
    },
    setUk({commit}, id) {
      commit('SET_UK', id)
      localStorage.setItem("u_uk", JSON.stringify(id));
    },
    setUkList({commit}, data) {
      commit('SET_UK_LIST', data)
    },
    async me({ commit, dispatch }) {
      let user = localStorage.getItem("u_user");
      user = JSON.parse(user);

      if (user) {
        commit("SET_USER", user);
        dispatch('setUpDataByRole', user)
      }

      let phone = localStorage.getItem("u_phone");

      if (phone) {
        phone = JSON.parse(phone);
        commit("SET_PHONE", phone);
      }

      let step = localStorage.getItem("u_step");

      if (step) {
        step = JSON.parse(step);
        commit("SET_STEP", step);
      }

      let sms = localStorage.getItem("u_sms");

      if (sms) {
        sms = JSON.parse(sms);
        commit("SET_SMS", sms);
      }

      let document = localStorage.getItem("u_document");

      if (document) {
        document = JSON.parse(document);
        commit("SET_DOCUMENT", document);
      }

      let xml = localStorage.getItem("u_xml");

      if (xml) {
        xml = JSON.parse(xml);
        commit("SET_XML_DATA", xml);
      }
    },
    async getHouseListByOsi({ dispatch }, id) {
      if (id === null) return
      await showOsi(id)
      .then(res => {
        dispatch('setHouseList', res.houses)
      })
    },
    async getKzhk({ dispatch }){
      await getKzhkHouses()
      .then(r => {
        let kzhkHouses = r.map(x => {
          return {
            id: x.id,
            fullName: x.fullName
          }
        })

        dispatch('setHouseList', kzhkHouses)
        if (kzhkHouses.length > 0) {
          if (!this.houseId) {
            dispatch('setHouse', kzhkHouses[0].id)
          }
        }
      })
    },
    async getOsiList({ commit}) {
      await getAllOsi({
        PageNumber: 1,
        PageSize: 10000,
      })
      .then(r => {
        commit('SET_OSI_LIST', r?.data)
      })
    },
    async getUkList({ commit}) {
      await getAllUk({
        PageNumber: 1,
        PageSize: 10000,
      })
      .then(r => {
        commit('SET_UK_LIST', r?.data)
      })
    },
    async getOsiListByMc({ commit, dispatch, state }, id) {
      await showUk(id).then(r => {
        let managementCompanyOsis = r.managementCompanyOsis.filter(x => x.osi != null)
        let managementCompanyHouses = r.managementCompanyOsis.filter(x => x.house != null)
        let osis = managementCompanyOsis.map(x => {
          return {
            id: x.osi.id,
            name: x.osi.name,
            type: x.osi.type
          }
        })

        if(osis.length > 0) {
          commit('SET_OSI_LIST', osis)
        }

        let houses = managementCompanyHouses.map(x => {
          return {
            id: x.house.id,
            fullName: x.house.fullName
          }
        })

        if(houses.length > 0) {
          commit('SET_HOUSE_LIST', houses)
        }
      }).catch(err => {
        dispatch('showAlert', {
          isVisible: true,
          msg: err.response.data.Message,
          color: "error",
          type: "error",
        })
      })
    },
    async showAlert({ commit }, alert) {
      commit("SET_ALERT", alert);

      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          commit("SET_ALERT", {
            isVisible: false,
            msg: "",
            color: "",
            type: "error",
          });
          resolve();
        }, 5000);
      });
    },
    setApplication({ commit }, val) {
      commit("SET_APPLICATION", val);
      localStorage.setItem("u_application", JSON.stringify(val));
    },
    setCancelXmlData({ commit }, val) {
      commit("SET_CANCEL_XML_DATA", val);
      localStorage.setItem("u_cancel_xml", JSON.stringify(val));
    },
    clearCancelXml({commit}) {
      commit("SET_CANCEL_XML_DATA", null);
      localStorage.removeItem("u_cancel_xml");
    },
    setTbXmlData({ commit }, val) {
      commit("SET_TB_XML_DATA", val);
      localStorage.setItem("u_tb_xml", JSON.stringify(val));
    },
    clearTbXml() {
      localStorage.removeItem("u_tb_xml");
    },
    setLeaderXmlData({ commit }, val) {
      commit("SET_LEADER_XML_DATA", val);
      localStorage.setItem("u_leader_xml", JSON.stringify(val));
    },
    setLeaderDocId({ commit }, val) {
      commit("SET_LEADER_DOC_ID", val);
      localStorage.setItem("u_leader_doc", JSON.stringify(val));
    },
    clearLeaderXml() {
      localStorage.removeItem("u_leader_xml");
    },
    clearLeaderDocId() {
      localStorage.removeItem("u_leader_doc");
    },
    deleteApplication({commit}) {
      commit('SET_APPLICATION', {})
      localStorage.removeItem("u_application");
    },
    deleteApplicationStep({commit}) {
      commit('SET_APPLICATION_STEP', 1)
      localStorage.removeItem("u_application_step");
    },
    setApplicationStep({ commit }, val) {
      commit("SET_APPLICATION_STEP", val);
      localStorage.setItem("u_application_step", JSON.stringify(val));
    },
    setTestCountdown({ commit }, val) {
      commit("SET_TEST_COUNTDOWN", val);
      localStorage.setItem("u_test_countdown", JSON.stringify(val));
    },
    clearTestCountdown() {
      localStorage.removeItem("u_test_countdown");
    },
  }
})
