import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-12"},[_c('h3',[_vm._v(_vm._s(_vm.title)+" ")]),_c(VRow,[_c(VCol,[_c('div',{staticClass:"text-center pt-2 pb-5"},[_c(VPagination,{attrs:{"length":_vm.pageCount,"total-visible":10},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1),_c(VDataTable,{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.files,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading-text":"Loading... Please wait","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.index`,fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:`item.docTypeName`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.docTypeName))]),(item.docKindName)?_c('span',[_vm._v(_vm._s(" (" + item.docKindName + ")"))]):_vm._e()]}},{key:`item.url`,fn:function({ item }){return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c(VBtn,{staticClass:"mr-5",attrs:{"dark":"","small":"","color":"primary"}},[_vm._v("Открыть ")])],1),(_vm.isRemovable)?_c(VBtn,{attrs:{"dark":"","small":"","color":"red"},on:{"click":() => {
          _vm.prompt = true
          _vm.removableFileId = item.id
        }}},[_vm._v("Удалить ")]):_vm._e()]}}],null,true)}),_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.prompt),callback:function ($$v) {_vm.prompt=$$v},expression:"prompt"}},[_c(VCard,{staticClass:"pa-2"},[_c(VCardTitle,{staticClass:"text-h5 text-center"},[_vm._v("Вы уверены, что хотите удалить?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.prompt=false}}},[_vm._v("Отмена")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.removeFile}},[_vm._v("ДА")]),_c(VSpacer)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }