import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "@/plugins/i18n";
import Vuetify from "vuetify";
import vuetify from "@/plugins/vuetify";
import VueMask from 'v-mask'
import axiosSetup from './http/axiosSetup'
Vue.use(VueMask);
Vue.use(Vuetify);

Vue.config.productionTip = false

store.dispatch("me").then(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: function (h) {
      return h(App)
    }
  }).$mount('#app')
})
