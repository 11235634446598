export const filters = {
    osi: {
        id: null,
        name: null
    },
    mc: {
        id: null,
        name: null
    },
    house: {
        id: null,
        name: null
    },
    applications: {
        status: null,
        chairpersonFullName: null,
        address: null,
        pageNumber: 1,
        pageSize: 10
    },
    clApplications: {
        status: null,
        chairpersonFullName: null,
        address: null,
        pageNumber: 1,
        pageSize: 10
    },
    tickets: {
        houseIds: [],
        status: null,
        expectedExecutionDate: null,
        categoryIds: [],
        pageNumber: 1,
        pageSize: 10
    }
}