import Vuetify from "vuetify";

export default new Vuetify({
    theme: {
        themes: {
            light: {
                secondary: "#b0bec5",
                accent: "#8c9eff",
                //error: "#b71c1c",
                brand: "#FF9800",
                primary: "#FF9800"
            },
        },
    },
});
