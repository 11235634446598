import axios from "axios";
const baseUrl = process.env.VUE_APP_ROOT_API+'/work-categories'

    export async function getWorkCategories() {
        let items = []
        const response = await axios.get(baseUrl, {
            params: {
                PageNumber: 1,
                PageSize: 10000
            }
        })

        items = response.data.data
        return items
    }