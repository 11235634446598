import axios from "axios";
const baseUrl = process.env.VUE_APP_ROOT_API+'/tickets/'

export async function getAllTickets(params) {
    let items = []
    const response = await axios.post(baseUrl + 'all', params)

    items = response?.data
    return items
}

export async function getAllTicketsByWdId(params) {
    let items = []
    const response = await axios.post(baseUrl + 'wd', params)

    items = response?.data
    return items
}

export async function getAllTicketsByOsiId(params) {
    let items = []
    const response = await axios.post(baseUrl + 'osi', params)

    items = response?.data
    return items
}

export async function getAllTicketsByMcId(params) {
    let items = []
    const response = await axios.post(baseUrl + 'mc', params)

    items = response?.data
    return items
}

export async function getAllTicketsByDispatcher(params) {
    let items = []
    let response = await axios.post(baseUrl + 'dispatcher', params)

    items = response?.data
    return items
}

export async function getAllTicketsByEmergencyDispatcher(params) {
    let items = []
    let response = await axios.post(baseUrl + 'emergency-dispatcher', params)

    items = response?.data
    return items
}

// export async function getAllMcTickets(id, params) {
//     let items = []
//     const response = await axios.get(baseUrl+'mc/'+id, {
//         params: {
//             PageNumber: 1,
//             PageSize: 10000,
//             ...params
//         }
//     })

//     items = response.data.data
//     return items
// }

export async function createTicket(params) {
    const response = await axios.post(baseUrl, params)
    return response.data.data
}

export async function getTicket(id) {
    const response = await axios.get(baseUrl+id)
    return response.data.data
}

export async function updateTicket(id, params) {
    const response = await axios.put(baseUrl+id, params)
    return response.data
}

export async function acceptTicket(id, params) {
    const response = await axios.put(baseUrl+'accept/'+id, params)
    return response.data.data
}

export async function transferTicketToWd(id, data) {
    const response = await axios.put(baseUrl+'wd/transfer/'+id, data)
    return response.data.data
}

export async function transferTicketToMc(id, data) {
    const response = await axios.put(baseUrl+'mc/transfer/'+id, data)
    return response.data.data
}

export async function rejectTicket(id) {
    const response = await axios.put(baseUrl+'reject/'+id)
    return response.data.data
}

export async function completeTicket(id, params) {
    const response = await axios.put(baseUrl+'complete/'+id, params)
    return response.data.data
}

export async function uploadTicketFiles(id, dataForm) {
    const response = await axios({
        method: 'POST',
        url: baseUrl+id+'/files',
        data: dataForm,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    return response.data.data
}

export async function removeTicketFile(id) {
    const response = await axios.delete(baseUrl+'files/'+id)
    return response.data.data
}

export async function getReport(params) {
    let items = []

    const response = await axios.get(baseUrl+'report', {
        params: params,
        responseType: 'blob'
    })

    items = response.data
    return items
}