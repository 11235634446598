const state = () => ({
    tickets_query: null,
    osi_query: null,
    uk_query: null,
    application_query: null,
    announcements_query: null,
    voting_query: null,
    residents_query: null,
    employees_query: null,
    dispatchers_query: null,
    emergency_dispatchers_query: null,
    emergency_calls_query: null,
    uk_employees_query: null,
    wd_employees_query: null,
    initiators_query: null,
    houses_query: null,
    wd_tickets_query: null
})

const getters = {
    tickets_query(state) {
        return state.tickets_query
    },
    wd_tickets_query(state) {
        return state.wd_tickets_query
    },
    osi_query(state) {
        return state.osi_query
    },
    uk_query(state) {
        return state.uk_query
    },
    application_query(state) {
        return state.application_query
    },
    announcements_query(state) {
        return state.announcements_query
    },
    voting_query(state) {
        return state.voting_query
    },
    residents_query(state) {
        return state.residents_query
    },
    employees_query(state) {
        return state.employees_query
    },
    uk_employees_query(state) {
        return state.uk_employees_query
    },
    wd_employees_query(state) {
        return state.wd_employees_query
    },
    initiators_query(state) {
        return state.initiators_query
    },
    houses_query(state) {
        return state.houses_query
    }
}

const mutations = {
    SET_TICKETS_QUERY (state, value) {
        state.tickets_query = value
    },
    SET_WD_TICKETS_QUERY (state, value) {
        state.wd_tickets_query = value
    },
    SET_OSI_QUERY (state, value) {
        state.osi_query = value
    },
    SET_UK_QUERY (state, value) {
        state.uk_query = value
    },
    SET_APPLICATION_QUERY (state, value) {
        state.application_query = value
    },
    SET_ANNOUNCEMENTS_QUERY (state, value) {
        state.announcements_query = value
    },
    SET_VOTING_QUERY (state, value) {
        state.voting_query = value
    },
    SET_RESIDENTS_QUERY (state, value) {
        state.residents_query = value
    },
    SET_EMPLOYEES_QUERY (state, value) {
        state.employees_query = value
    },
    SET_DISPATCHERS_QUERY (state, value) {
        state.dispatchers_query = value
    },
    SET_EMERGENCY_DISPATCHERS_QUERY (state, value) {
        state.emergency_dispatchers_query = value
    },
    SET_EMERGENCY_CALLS_QUERY (state, value) {
        state.emergency_calls_query = value
    },
    SET_UK_EMPLOYEES_QUERY (state, value) {
        state.uk_employees_query = value
    },
    SET_WD_EMPLOYEES_QUERY (state, value) {
        state.wd_employees_query = value
    },
    SET_INITIATORS_QUERY (state, value) {
        state.initiators_query = value
    },
    SET_HOUSES_QUERY (state, value) {
        state.houses_query = value
    },
}

const actions = {
    getTicketsQuery({ commit}) {
        let query = localStorage.getItem("u_tickets_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_TICKETS_QUERY", query);
        }
        return query
    },
    setTicketsQuery({ commit}, data) {
        commit('SET_TICKETS_QUERY', data)
        localStorage.setItem("u_tickets_query", JSON.stringify(data));
    },
    getWdTicketsQuery({ commit}) {
        let query = localStorage.getItem("u_wd_tickets_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_WD_TICKETS_QUERY", query);
        }
        return query
    },
    setWdTicketsQuery({ commit}, data) {
        commit('SET_WD_TICKETS_QUERY', data)
        localStorage.setItem("u_wd_tickets_query", JSON.stringify(data));
    },
    getOsiQuery({ commit}) {
        let query = localStorage.getItem("u_osi_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_OSI_QUERY", query);
        }
    },
    setOsiQuery({ commit}, data) {
        commit('SET_OSI_QUERY', data)
        localStorage.setItem("u_osi_query", JSON.stringify(data));
    },
    getUkQuery({ commit}) {
        let query = localStorage.getItem("u_uk_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_UK_QUERY", query);
        }
    },
    setUkQuery({ commit}, data) {
        commit('SET_UK_QUERY', data)
        localStorage.setItem("u_uk_query", JSON.stringify(data));
    },
    getApplicationQuery({ commit}) {
        let query = localStorage.getItem("u_application_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_APPLICATION_QUERY", query);
        }
    },
    setApplicationQuery({ commit}, data) {
        commit('SET_APPLICATION_QUERY', data)
        localStorage.setItem("u_application_query", JSON.stringify(data));
    },
    getAnnouncementsQuery({ commit}) {
        let query = localStorage.getItem("u_announcements_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_ANNOUNCEMENTS_QUERY", query);
        }

        return query
    },
    setAnnouncementsQuery({ commit}, data) {
        commit('SET_ANNOUNCEMENTS_QUERY', data)
        localStorage.setItem("u_announcements_query", JSON.stringify(data));
    },
    getVotingQuery({ commit}) {
        let query = localStorage.getItem("u_voting_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_VOTING_QUERY", query);
        }
    },
    setVotingQuery({ commit}, data) {
        commit('SET_VOTING_QUERY', data)
        localStorage.setItem("u_voting_query", JSON.stringify(data));
    },
    getResidentsQuery({ commit}) {
        let query = localStorage.getItem("u_residents_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_RESIDENTS_QUERY", query);
        }
    },
    setResidentsQuery({ commit}, data) {
        commit('SET_RESIDENTS_QUERY', data)
        localStorage.setItem("u_residents_query", JSON.stringify(data));
    },
    getEmployeesQuery({ commit}) {
        let query = localStorage.getItem("u_employees_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_EMPLOYEES_QUERY", query);
        }
        return query
    },
    getDispatchersQuery({ commit}) {
        let query = localStorage.getItem("u_dispatchers_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_DISPATCHERS_QUERY", query);
        }
        return query
    },
    setDispatchersQuery({ commit}, data) {
        commit('SET_DISPATCHERS_QUERY', data)
        localStorage.setItem("u_dispatchers_query", JSON.stringify(data));
    },
    getEmergencyDispatchersQuery({ commit}) {
        let query = localStorage.getItem("u_emergency_dispatchers_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_EMERGENCY_DISPATCHERS_QUERY", query);
        }
        return query
    },
    setEmergencyDispatchersQuery({ commit}, data) {
        commit('SET_EMERGENCY_DISPATCHERS_QUERY', data)
        localStorage.setItem("u_emergency_dispatchers_query", JSON.stringify(data));
    },
    getEmergencyCallsQuery({ commit}) {
        let query = localStorage.getItem("u_emergency_calls_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_EMERGENCY_CALLS_QUERY", query);
        }
        return query
    },
    setEmergencyCallsQuery({ commit}, data) {
        commit('SET_EMERGENCY_CALLS_QUERY', data)
        localStorage.setItem("u_emergency_calls_query", JSON.stringify(data));
    },
    setEmployeesQuery({ commit}, data) {
        commit('SET_EMPLOYEES_QUERY', data)
        localStorage.setItem("u_employees_query", JSON.stringify(data));
    },
    getUkEmployeesQuery({ commit}) {
        let query = localStorage.getItem("u_uk_employees_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_UK_EMPLOYEES_QUERY", query);
        }
        return query
    },
    setUkEmployeesQuery({ commit}, data) {
        commit('SET_UK_EMPLOYEES_QUERY', data)
        localStorage.setItem("u_uk_employees_query", JSON.stringify(data));
    },
    getWdEmployeesQuery({ commit}) {
        let query = localStorage.getItem("u_wd_employees_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_WD_EMPLOYEES_QUERY", query);
        }
        return query
    },
    setWdEmployeesQuery({ commit}, data) {
        commit('SET_WD_EMPLOYEES_QUERY', data)
        localStorage.setItem("u_wd_employees_query", JSON.stringify(data));
    },
    getInitiatorsQuery({ commit}) {
        let query = localStorage.getItem("u_initiators_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_INITIATORS_QUERY", query);
        }
    },
    setInitiatorsQuery({ commit}, data) {
        commit('SET_INITIATORS_QUERY', data)
        localStorage.setItem("u_initiators_query", JSON.stringify(data));
    },
    getHousesQuery({ commit}) {
        let query = localStorage.getItem("u_houses_query");

        if (query !== 'undefined') {
            query = JSON.parse(query);
            commit("SET_HOUSES_QUERY", query);
        }
    },
    setHousesQuery({ commit}, data) {
        commit('SET_HOUSES_QUERY', data)
        localStorage.setItem("u_houses_query", JSON.stringify(data));
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
