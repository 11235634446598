import axios from "axios";
const baseUrl = process.env.VUE_APP_ROOT_API+'/osis'

export async function getAllOsi(params) {
    let items = []
    const response = await axios.get(baseUrl, {
        params: params
    })

    items = response?.data
    return items
}

export async function createOsi(params) {
    const response = await axios.post(baseUrl, params)
    return response.data.data
}

export async function showOsi(id) {
    const response = await axios.get(baseUrl+'/'+id)
    return response.data.data
}

export async function updateOsi(id, params) {
    const response = await axios.put(baseUrl+'/'+id, params)
    return response.data
}

export async function destroyOsi(id) {
    const response = await axios.delete(baseUrl+'/'+id)
    return response.data.data
}

export async function registerOsi(params) {
    const response = await axios.post(baseUrl + '/registration', params)
    return response.data
}

export async function getAllOsiRegistrations(params) {
    const response = await axios.get(baseUrl + '/registration', {
        params: params
    })
    return response.data
}

export async function getOsiRegistration(id) {
    let items = []
    const response = await axios.get(baseUrl+'/registration/' + id)

    items = response.data.data
    return items
}

export async function checkOsiName(params) {
    const response = await axios.post(baseUrl + '/registration/check-name', params)
    return response.data.data
}

export async function signData(params) {
    const response = await axios.post(baseUrl + '/registration/signed-document', params)
    return response.data.data
}

export async function osiPosts(params) {
    const response = await axios.get(baseUrl+'/posts', {
        params: params
    })
    return response.data.data
}

export async function checkOsi(rka) {
    const response = await axios.get(baseUrl+'/registration/check', {
        params: {
            Rka: rka
        }
    })
    return response.data
}

export async function uploadOsiRegistrationFiles(id, dataForm) {
    const response = await axios({
        method: 'POST',
        url: baseUrl+'/'+id+'/files',
        data: dataForm,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    return response.data.data
}

export async function removeOsiRegistrationFile(id) {
    const response = await axios.delete(baseUrl+'/registration/file/'+id)
    return response.data.data
}

export async function uploadOsiRegistrationFilesNew(data) {
    const response = await axios({
        method: 'POST',
        url: baseUrl+'/registration/file',
        data: data,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    return response.data.data
}

export async function uploadOsiFiles(data) {
    const response = await axios({
        method: 'POST',
        url: baseUrl+'/file',
        data: data,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    return response.data.data
}

export async function generateUstav(params) {
    const response = await axios.post(baseUrl + '/registration/ustav', params)
    return response.data
}

export async function getRegistrationStatus() {
    const response = await axios.get(baseUrl+'/registration/status')
    return response.data.data
}

export async function checkCondominium(params) {
    const response = await axios.get(baseUrl+'/registration/condominium', {
        params: params
    })
    return response.data.data
}

export async function getRegistrationData() {
    const response = await axios.get(baseUrl+'/registration/questionnaire')
    return response.data.data
}

export async function saveRegistrationData(params) {
    const response = await axios.post(baseUrl+'/registration/questionnaire', params)
    return response.data
}

export async function timeBreak(params) {
    const response = await axios.post(baseUrl+'/registration/time-break', params)
    return response.data
}

export async function timeBreakSigned(params) {
    const response = await axios.post(baseUrl+'/registration/time-break/signed', params)
    return response.data.data
}

export async function cancellation(params) {
    const response = await axios.post(baseUrl+'/registration/cancellation', params)
    return response.data
}

export async function cancellationSigned(params) {
    const response = await axios.post(baseUrl+'/registration/cancellation/signed', params)
    return response.data.data
}

export async function getChairmanInfo(params) {
    const response = await axios.get(baseUrl+'/info/chairman', params)
    return response.data.data
}

export async function getBankAccountLink(params) {
    const response = await axios.get(baseUrl+'/registration/bank/account', params)
    return response.data
}

export async function getRegOrgan(params) {
    const response = await axios.get(baseUrl+'/registration/regorgan', {
        params: params
    })
    return response.data.data
}

export async function getStateDutyUrl(params) {
    const response = await axios.post(baseUrl+'/registration/freedompay/stateduty', params)
    return response.data
}