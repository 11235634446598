import {filters} from "@/helpers/filters";

const state = () => ({
    osi: filters.osi,
    mc: filters.mc,
    house: filters.house
})

const mutations = {
    SET_OSI(state, value) {
        state.osi = value
    },
    SET_MC(state, value) {
        state.mc = value
    },
    SET_HOUSE(state, value) {
        state.house = value
    }
}


export default {
    namespaced: true,
    state
}