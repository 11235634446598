<template>
  <div class="mb-12">
    <h3>{{ title }} </h3>
    <v-row>
        <v-col>
          <div class="text-center pt-2 pb-5">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="10"
              @input="changePage"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    <v-data-table
      :headers="headers"
      :items="files"
      :page="page"
      :items-per-page="itemsPerPage"
      loading-text="Loading... Please wait"
      hide-default-footer
      class="elevation-1"
      style="cursor: pointer"
      >
      <template v-slot:[`item.index`]="{ index }">
        {{ index + 1 }}
      </template>

      <template v-slot:[`item.docTypeName`]="{ item }">
        <span>{{ item.docTypeName }}</span>
        <span v-if="item.docKindName">{{ " (" + item.docKindName + ")" }}</span>
      </template>

      <template v-slot:[`item.url`]="{ item }">
        <a :href="item.url" target="_blank">
          <v-btn
            class="mr-5" 
            dark
            small
            color="primary"
            >Открыть
          </v-btn>
        </a>
        <v-btn 
          v-if="isRemovable"
          dark
          small
          color="red"
          @click="() => {
            prompt = true
            removableFileId = item.id
          }"
          >Удалить
        </v-btn>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="prompt" max-width="500px">
          <v-card class="pa-2">
            <v-card-title class="text-h5 text-center">Вы уверены, что хотите удалить?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="prompt=false">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="removeFile">ДА</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Files",
  props: {
    files: Array,
    title: String,
    isRemovable: Boolean
  },
  data: () => ({
    headers: [
      { text: "#", value: "index" },
      { text: "Название", value: "name" },
      { text: "Тип документа", value: "docTypeName" },
      { text: "Действие", value: "url" }
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    removableFileId: null,
    prompt: false,
  }),
  mounted() {
    let mod = this.files.length % this.itemsPerPage
    let count = Math.floor(this.files.length / 10)
    this.pageCount = mod === 0 ? count : count + 1
  },
  methods: {
    removeFile() {
      this.$emit('remove', this.removableFileId)
      this.prompt = false
    },
    changePage(event) {
      this.page = event
    },
  }
}
</script>