import VueI18n from 'vue-i18n'
import Vue from 'vue';
import messages from "@/plugins/messages";
import store from "@/store";

Vue.use(VueI18n)

let locale = localStorage.u_locale ?? 'ru'
store.dispatch('setLocale', locale)

export default new VueI18n({
    locale: locale, // set locale
    fallbackLocale: 'ru',
    messages
})
