import axios from "axios";
const baseUrl = process.env.VUE_APP_ROOT_API+'/wd-employees'

export async function getAllEmployeesByWdId(id, params) {
    let items = []
    const response = await axios.get(baseUrl + '/wd/' + id, {
        params: params
    })

    items = response.data.data
    return items
}

export async function getWdEmployeeById(id, params) {
    const response = await axios.get(baseUrl+'/'+id, {
        params: params
    })
    return response.data
}

export async function getAllWdEmployee() {
    let items = []
    const response = await axios.get(baseUrl, {
        params: {
            PageNumber: 1,
            PageSize: 10000
        }
    })

    items = response.data.data
    return items
}

export async function createWdEmployee(params) {
    const response = await axios.post(baseUrl, params)
    return response.data.data
}

export async function updateWdEmployee(id, params) {
    const response = await axios.put(baseUrl+'/'+id, params)
    return response.data
}

export async function deleteWdEmployee(id) {
    const response = await axios.delete(baseUrl+'/'+id)
    return response.data.data
}