export const Role = {
    superAdmin: 'SuperAdmin',
    osi: 'OSIManager',
    uk: 'ManagementCompany',
    lowOsiManager: 'LowOsiManager',
    wd: 'WarrantyDepartment',
    kzhk: 'KzhkUser',
    dispatcher: 'Dispatcher',
    kzhkAdmin: 'KzhkAdmin',
    emergencyDispatcher: 'EmergencyDispatcher'
}