import axios from "axios";
const baseUrl = process.env.VUE_APP_ROOT_API+'/employees'

export async function getAllEmployeeByOsiId(id, params) {
    let items = []
    const response = await axios.get(baseUrl+'/osi/'+id, {
        params: params
    })

    items = response.data
    return items
}

export async function getAllEmployee() {
    let items = []
    const response = await axios.get(baseUrl, {
        params: {
            PageNumber: 1,
            PageSize: 1000
        }
    })

    items = response.data.data
    return items
}

export async function getEmployeeById(id) {
    const response = await axios.get(baseUrl+'/'+id)
    return response.data.data
}

export async function createEmployee(params) {
    const response = await axios.post(baseUrl, params)
    return response.data.data
}

export async function updateEmployee(id, params) {
    const response = await axios.put(baseUrl+'/'+id, params)
    return response.data
}

export async function deleteEmployee(id) {
    const response = await axios.delete(baseUrl+'/'+id)
    return response.data.data
}