import axios from "axios";
const baseUrl = process.env.VUE_APP_ROOT_API+'/mc-employees'

export async function getAllEmployeeByMcId(id, params) {
    let items = []
    const response = await axios.get(baseUrl+'/mc/'+id, {
        params: params
    })

    items = response.data
    return items
}

export async function getMcEmployeeById(id, params) {
    const response = await axios.get(baseUrl+'/'+id, {
        params: params
    })
    return response.data
}

export async function getAllMcEmployee() {
    let items = []
    const response = await axios.get(baseUrl, {
        params: {
            PageNumber: 1,
            PageSize: 10000
        }
    })

    items = response.data.data
    return items
}

export async function createMcEmployee(params) {
    const response = await axios.post(baseUrl, params)
    return response.data.data
}

export async function updateMcEmployee(id, params) {
    const response = await axios.put(baseUrl+'/'+id, params)
    return response.data
}

export async function deleteMcEmployee(id) {
    const response = await axios.delete(baseUrl+'/'+id)
    return response.data.data
}