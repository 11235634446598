import store from '../store'
window.axios = require("axios");
window.axios.defaults.withCredentials = true;

let user = localStorage.u_user;
if (user) {
    user = JSON.parse(user);
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
}

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        switch (error.response.status) {
            case 401:
                store.dispatch("logout").then(() => {});
                break;
            case 419:
                store.dispatch("logout").then(() => {});
                break;
            default:
                return Promise.reject(error);
        }
    }
);